export default [
  {
    id: '1',
    image: '26',
    title: '订单管理系统',
    simple: 'OMS',
    url: '/mobileCloudPlatformDetail/1',
    descript: '订单全生命周期过程管理与可视追踪',
    detail: {
      image: '38',
      imageWidth: 12.89,
      imageHeight: 5.75,
      descript: [
        {
          title: '资源集中并网组建物流网络',
          descript: '集中管理仓储、运力，库存资源、协同内外部多主体、多仓库、多运段联合运营，平台化模式运作，提升企业物流整体能力'
        },
        {
          title: '无缝对接与精益的计划管控',
          descript: '订单多渠道、多方式无缝对接，订单与业务产品规则自动匹配，自动拆合单、实时线路班次计划调度提醒，提高订单履约率'
        },
        {
          title: '智能任务调度与过程协同控制',
          descript: '智能路由、任务调度、任务分解与合并、资源分配，任务链路管控协同、降低调度人员工作难度，发挥规模效益，节省成本'
        },
        {
          title: '全景数字监管与主动预警防范',
          descript: '一码流通，从订单到交付全生命周期可视化跟踪、全链路时效、交付质量主动预警和自动审计，准确交付，提高客户满足度'
        }
      ]
    }
  },
  {
    id: '2',
    image: '27',
    title: '仓储管理系统',
    simple: 'WMS',
    url: '/mobileCloudPlatformDetail/2',
    descript: '实现平台化的仓储管理要求',
    detail: {
      image: '40',
      imageWidth: 11.45,
      imageHeight: 4.93,
      descript: [
        {
          title: '多业态与多作业模式支持',
          descript: '满足时尚、快消、医药、制造业、电商、母婴、冷链、第三方等业态仓储管理需求，支持生产、批发、连锁、零售仓储分拣与快运中转不同作业模式'
        },
        {
          title: '多仓与多货主的仓储平台',
          descript: '多物流中心、多仓库、多货主的集中仓储管理平台，信息互通、支持资源共享应用、快速开仓，多仓协同，仓间联动，支持业主间库内货权转移'
        },
        {
          title: '运营与作业管理全应用覆盖',
          descript: '从基础管理、运营管理、流程管理全应用覆盖。内置资源、计划、任务、效率、响应及时性、压力、绩效等监控和预警组件，可自定义组装物流中心全景动态看板，数字化决策任务调度与流程监控'
        },
        {
          title: '无缝对接周边系统，插件式适配智能装备',
          descript: '开放API，与周边或客户系统无缝对接。系统参数可配、策略可配、流程可定义及二次开发平台的支持，具备高扩展性，高效响应复杂业务场景。可自定义智能设备接口，插件式集成管理智能设备'
        }
      ]
    }
  },
  {
    id: '3',
    image: '28',
    title: '运输管理系统',
    simple: 'TMS',
    url: '/mobileCloudPlatformDetail/3',
    descript: '实现运输作业过程全场景管理应用',
    detail: {
      image: '39',
      imageWidth: 7.12,
      imageHeight: 4.39,
      descript: [
        {
          title: '全过程透明可视可追溯',
          descript: '无缝对接上下游客户，全运作过程可视追溯跟踪，主动通知客户交付'
        },
        {
          title: '多维度实时计划控制面板',
          descript: '订单计划、任务状态、交付状态、温控、位置路径多维度实时运营看板，主动监控，及时预警'
        },
        {
          title: '全流程管理系统应用覆盖',
          descript: '计划调度、任务生成，任务指派、任务接受、出车、提货、装车、在途、交付、回车车检作业过程应用全覆盖'
        },
        {
          title: '自定义配置流程与规则的运力管理',
          descript: '可配置车队/车辆，车务，规费、绩效等管理工作流程与监管策略，可自定义规则自动生成管理报表'
        },
        {
          title: '可视化调度与AI智能调度支持',
          descript: '地图线路规划、基于规则自动拆合单、大数据和AI人工智能装载分车和自动路径规划等智能调度'
        }
      ]
    }
  },
  {
    id: '4',
    image: '29',
    title: '计费管理系统',
    simple: 'BMS',
    url: '/mobileCloudPlatformDetail/4',
    descript: '精细化核算，运用数据指导经营决策',
    detail: {
      image: '41',
      imageWidth: 8.97,
      imageHeight: 5.94,
      descript: [
        {
          title: '费用集中管控，计费与业务过程融合',
          descript: '费用集中共享管控，业务过程与计费过程一体化融合。支持多帐套、分类帐套管理'
        },
        {
          title: '标准费用核算流程与多方式和规则支持',
          descript: '规范化服务项目、产品、合同协议管理，标准化核算流程。支持多种计费方式和自定义计费规则'
        },
        {
          title: '最小计量单元的精细成本核算',
          descript: '规范化服务项目、产品、合同协议管理，标准化核算流程。支持多种计费方式和自定义计费规则'
        },
        {
          title: '最小计量单元的精细成本核算',
          descript: '统一成本核算口径，基于作业成本法的核算，精细到作业最小计量单元，支持任意扩展的核算维度'
        },
        {
          title: '过程闭环管理与全流程追溯',
          descript: '费用从核算到核销全过程闭环管理，全流程追溯。自动生成经营相关报表。支持与财务系统无缝对接'
        }
      ]
    }
  },
  {
    id: '5',
    image: '30',
    title: '运营分析系统',
    simple: 'OAS',
    url: '/mobileCloudPlatformDetail/5',
    descript: '构建物流数据资产与应用体系',
    detail: {
      image: '42',
      imageWidth: 9.1,
      imageHeight: 6,
      descript: [
        {
          title: '内置行业分析模型，精益分析决策',
          descript: '以物流行业运营管理模型为驱动，提供的分析决策应用覆盖资源评价、运营压力、作业效率、交付时效、经营质量及KPI考核多个维度'
        },
        {
          title: '可视化开发工具组件',
          descript: '内置丰富的行业应用模板及可视化在线开发工具，图形化可视编辑，可轻松研发实时监控预警、数据大屏、 即席查询、自动化报表、分析诊断多类型应用'
        },
        {
          title: '企业物流数据中台建设',
          descript: '提供完整的管理应用与开发组件，数据交换、质量、指标、标签体系完全可配置， 数据集成、数据开发、数据应用全开发流程支持，将分散的数据快速形成数据资产及构建数据中台服务'
        },
        {
          title: '基于大数据架构的运算处理',
          descript: '基于大数据架构，支撑高吞吐量的数据接入、海量数据处理能力与高并发的数据调用'
        }
      ]
    }
  }
]