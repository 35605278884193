<!-- SAAS云服务模块组件 -->
<template>
  <div class="mobile-cloud-platform">
    <div class="item" v-for="item in cloudPlatformData" :key="item.id" @click="goDetail(item.id)">
      <div class="left">
        <img :src="require(`@/assets/image/mobile/icon-${item.image}.png`)">
      </div>
      <div class="right">
        <div class="title">{{ item.title }}</div>
        <div class="descript">{{ item.descript }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import CloudPlatformData from './cloud-platform-data'

  export default {
    name: 'MobileCloudPlatform',
    data () {
      return {
        cloudPlatformData: CloudPlatformData
      }
    },
    methods: {
      // 跳转至详情页
      goDetail (id) {
        this.$router.push({
          path: `/mobileCloudPlatformDetail/${id}`
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/components/mobile/style/variable.scss';

  .mobile-cloud-platform {
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    .item {
      // height: 2.25rem;
      flex: 1;
      border-bottom: 1px solid $border-color-2;
      display: flex;
      align-items: center;
      padding: 0 0.55rem;
      font-size: $font-size-3;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $text-color-3;
      line-height: 0.36rem;
      .left {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.5rem;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .right {
        .title {
          font-size: $font-size-1;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: $text-color-1;
          margin-bottom: $distance-1;
        }
      }
      &:nth-last-of-type(1) {
        border-bottom: none;
      }
    }
  }
</style>